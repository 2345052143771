.timeline-alt .timeline-item {
  position: relative;
}
.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}
.timeline-alt .timeline-item:before {
  background-color: #f1f3fa;
  bottom: 0;
  content: "";
  // left: 9px;
  // top: 20px;
  position: absolute;
  left: 6.5px;
  top: 18px;
  width: 2px;
  z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
}
.timeline-alt .timeline-item .timeline-icon:first-child {
  margin-top: 2px;
}
.bg-primary-lighten {
  background-color: $primary-light !important;
}
.bg-secondary-lighten {
  background-color: $secondary-light !important;
}
.bg-info-lighten {
  background-color: $info-light!important;
}
.bg-success-lighten {
  background-color: $success-light!important;
}
.bg-danger-lighten {
  background-color: $danger-light!important;
}
.bg-warning-lighten {
  background-color: $warning-light!important;
}

