// FONT FAMILIES
.font-arial {
    // font-family: "Verdana, Geneva, Tahoma, sans-serif" !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

// FONT SIZES
.font-95 {
    font-size: 95% !important;
}

.font-90 {
    font-size: 90% !important;
}

.font-85 {
    font-size: 85% !important;
}

.font-80 {
    font-size: 80% !important;
}