@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&display=swap');

// Body
$body-bg: #f8fafc;
$body-color: #1e1e1e;
$body-bg: #fff;

// Typography
$font-family-sans-serif: system-ui, "-apple-system", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$primary: #2a92c2;
$primary-light: #b1def3;
$secondary: #2c3a5f;
$secondary-light: #7b849b;
$success: #39DA8A;
$success-light: #b5f1d3;
$danger: #FF5B5C;
$danger-light: #ff9899;
$warning: #FDAC41;
$warning-light: #fee0b8;
$info: #00CFDD;
$info-light: #9fedf2;
$dark: #222f3e;
$light: #f1f3f4;
$link: #2178d1;

%transition {
  transition: .3s all;
}

$enable-caret: true;

$spacer: 1rem;
$border-radius:               .25rem;

$line-height-base:            1.5;
$line-height-sm:              1.25;
$line-height-lg:              2;

$headings-margin-bottom:      $spacer / 2;
$headings-font-family:        "Raleway", Arial, "Helvetica Neue", Helvetica, sans-serif;
// $headings-font-family:        "Helvetica Neue", Helvetica, sans-serif;
$headings-font-weight:        300;
$headings-line-height:        1.2;

$link-hover-decoration: none;
$link-decoration: none;

.btn, .navbar-toggler {
  box-shadow: none !important;
  outline: none !important;
}
